<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>
        <b-card-text class="mb-2 text-center">
          <strong>{{ $t('src.views.pages.auth.resetpassword.resetYP') }}</strong>
        </b-card-text>

        <!-- form -->
        <validation-observer ref="emailForm">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="validationForm"
          >
            <b-form-group
              :label="$t('src.views.pages.auth.resetpassword.newP')"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="newPassword"
                    :placeholder="$t('src.views.pages.auth.resetpassword.4366')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('src.views.pages.auth.resetpassword.confirmNP')"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="passwordConfirm"
                    v-model="passwordconfirm"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="passwordconfirm"
                    :placeholder="$t('src.views.pages.auth.resetpassword.8333')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                small
                class="float-left"
                :label="$t('src.views.pages.auth.resetpassword.floatedR')"
              />{{ $t('src.views.pages.auth.resetpassword.confirm') }}</b-button>
            <b-card-text class="text-center mt-2">
              <b-link
                :to="{name:'login'}"
                class="text-secondary"
              >
                <span>{{ $t('src.views.pages.auth.resetpassword.cancel') }}</span>
              </b-link>
            </b-card-text>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BFormGroup, BFormInput, BSpinner, BForm, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { confirmed } from 'vee-validate/dist/rules'

extend('confirmed', {
  ...confirmed,
  message: 'Password does not match. Please re-enter a new password again',
})

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      password: '',
      passwordconfirm: '',
      required,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async validationForm() {
      const self = this
      this.$refs.emailForm.validate().then(async success => {
        if (success) {
          self.loading = true
          try {
            const response = await this.$http.post('/setPassword',
              {
                password: this.password,
                password_confirmation: this.passwordconfirm,
                token: this.$route.query.token,
                email: this.$route.query.email,
              })
            if (response.status === true) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Password Reset Successfully',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
              this.$router.replace('/login')
            }else{
              console.log(response);
              console.log(response.message);
              this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: response.message,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            self.loading = false
          } catch (error) {
            self.loading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
